import angular from 'angular';
import ngMessages from 'angular-messages';
import moment from 'moment';
import lodashRound from 'lodash/round';
import lodashGet from 'lodash/get';
import TkrAppConfig from '../../../common/TkrAppConfig';
import TkrSpaCommon from '../../../common/tkr-spa';
import { TkrPersonService } from '../../../common/tkr-commonservices';
import { SmartFloat, SmartInteger } from '../../../common/ma-smartfloat-1.0';
import TkrPpCommon from '../../../pp/common/0-pp-common-module';
import TkrPpResources from '../../../pp/prcportal-resource-modules';
import MaDatepicker from '../../../common/ma-datepicker-uib';
import { configureTranslateProvider } from '../../../pp/translation-helpers';
import ngTranslate from 'angular-translate';
import 'angular-translate-loader-static-files';
const subsidy_application_app = angular.module('ng-tkr-app-porvookp', [
    TkrSpaCommon,
    ngMessages,
    TkrPersonService,
    SmartFloat,
    SmartInteger,
    TkrPpResources,
    TkrAppConfig,
    TkrPpCommon,
    MaDatepicker,
    ngTranslate,
])
    .config(config)
    .config(configureTranslateProvider);
export default subsidy_application_app.name;
config.$inject = ['$stateProvider', '$urlRouterProvider'];
function config($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/err');
    $stateProvider
        .state('err', {
        url: '/err',
        template: '<p class="h4 text-danger">Sisäinen virhe. Ota yhteyttä helpdesk@matriset.fi.</p>'
    })
        .state('root', {
        url: '',
        template: '',
        controller: 'AppRedirCtrl',
        controllerAs: 'vm'
    })
        .state('lomake', {
        url: '/:applicationId',
        template: require('./kp-app.tpl.html'),
        controller: 'SubsidyApplicationController',
        controllerAs: 'vm',
        resolve: {
            app: ['PortalSubsidyAppService', '$stateParams', function (PortalSubsidyAppService, $stateParams) {
                    return PortalSubsidyAppService.get({ appId: $stateParams.applicationId }).$promise;
                }],
            appPeriod: ['PortalAppPeriodService', 'app', function (PortalAppPeriodService, app) {
                    return PortalAppPeriodService.get({ periodId: app.applicationPeriodId }).$promise;
                }]
        }
    })
        .state('jatetty', {
        url: '/:applicationId/jatetty',
        template: require('./app-submitted.tpl.html'),
        controller: 'AppSubmittedController',
        controllerAs: 'vm'
    });
}
subsidy_application_app.controller('SubsidyApplicationController', SubsidyApplicationController);
SubsidyApplicationController.$inject = [
    'app',
    'appPeriod',
    'TkrPersonService',
    '$state',
    '$translate'
];
function SubsidyApplicationController(app, appPeriod, TkrPersonService, $state, $translate) {
    var vm = this;
    vm.app = app;
    vm.vuosi = appPeriod.td.wnum.vuosi;
    vm.edvuosi = vm.vuosi - 1;
    vm.loppuAika = moment(appPeriod.end).local().format('D.M.YYYY HH:mm');
    vm.translationVars = {
        loppuAika: vm.loppuAika,
        edvuosi: vm.edvuosi,
        tiliSaldoErotus: 0,
        kpitoTulos: 0
    };
    vm.personRoles = TkrPersonService.personRoles;
    vm.chairmanRoles = TkrPersonService.chairmanRoles;
    vm.attachmentDefsKahd = [
        { type: 'tilinpaatos', name: 'liite_tilinpaatos', required: true },
        { type: 'muu', name: 'liite_muu_tai_selvitys', required: false },
        { type: 'digiroad', name: 'liite_digiroad', required: false },
        { type: 'mml', name: 'liite_mml', required: false }
    ];
    vm.attachmentDefsYhd = [
        { type: 'muu', name: 'liite_tai_selvitys', required: false },
        { type: 'digiroad', name: 'liite_digiroad', required: false },
        { type: 'mml', name: 'liite_mml', required: false }
    ];
    vm.preprocessApp = function (data) {
        if (!data.chairman) {
            data.chairman = {};
        }
        if (!data.contactPerson) {
            data.contactPerson = {};
        }
        if (!data.td) {
            data.td = {};
        }
        if (!data.td.num) {
            data.td.num = {};
        }
        if (!data.td.wnum) {
            data.td.wnum = {};
        }
        if (!data.td.bools) {
            data.td.bools = {};
        }
        if (!data.td.text) {
            data.td.text = {};
        }
        if (!data.addressNames) {
            data.addressNames = [];
        }
        if (data.addressNames.length == 0) {
            data.addressNames.push({ road: '', town: null });
        }
        if (!data.people) {
            data.people = {};
        }
        if (!data.people.vm) {
            data.people.vm = {};
        }
        if (!data.people.rh) {
            data.people.rh = {};
        }
        if (!data.people.s) {
            data.people.s = {};
        }
        vm.refreshCalculatedValues();
    };
    vm.contactIsChairman = function () {
        return TkrPersonService.isValidChairmanRole(lodashGet(vm.app, 'contactPerson.role'));
    };
    vm.isSeparateChairmanRequired = function () {
        return vm.contactIsChairman() ? false : true;
    };
    vm.prcHasHoitokunta = function () {
        var roleToCheck = lodashGet(vm.app, vm.isSeparateChairmanRequired() ? 'chairman.role' : 'contactPerson.role');
        return (roleToCheck === TkrPersonService.TM_ROLE_VALUE) ? false : true;
    };
    vm.postprocessApp = function (data) {
        if (!vm.isSeparateChairmanRequired()) {
            data.chairman = data.contactPerson;
        }
        if (vm.prcHasHoitokunta()) {
            data.people.vm = null;
            if (TkrPersonService.SIHTEERI_ROLE_VALUE === data.contactPerson.role) {
                data.people.s = {
                    firstName: data.contactPerson.firstName,
                    lastName: data.contactPerson.lastName,
                    emailAddress: data.contactPerson.emailAddress,
                    phoneNumber: data.contactPerson.phoneNumber
                };
            }
            else if (TkrPersonService.RH_ROLE_VALUE === data.contactPerson.role) {
                data.people.rh = {
                    firstName: data.contactPerson.firstName,
                    lastName: data.contactPerson.lastName,
                    emailAddress: data.contactPerson.emailAddress,
                    phoneNumber: data.contactPerson.phoneNumber
                };
            }
        }
        else {
            data.people.rh = null;
            data.people.s = null;
        }
        if (data.td.bools.isEligible) {
            data.established = true;
        }
        if (!data.td.bools.sahkoinenPaatos) {
            data.td.text.paatosEmail = null;
        }
        if (vm.app.td.text.muutto !== 'lisaa') {
            vm.app.td.text.mihinuusia = undefined;
        }
        if (vm.app.td.text.muutto !== 'pois') {
            vm.app.td.text.mistapois = undefined;
        }
    };
    vm.save = function () {
        vm.postprocessApp(vm.app);
        vm.app.applicationState = 'SUBMITTED';
        vm.app.$save({
            lang: $translate.use()
        }).then(function onSuccess(data) {
            if (data.id && data.id === vm.app.id) {
                $state.go('jatetty', { applicationId: data.id });
            }
            else {
                window.alert('VIRHE hakemusta ei tallennettu --- FEL bidragsansökan sparades inte');
            }
        });
    };
    function calcTulotYht() {
        var o = vm.app.td.num;
        var result = lodashRound(lodashRound(o.tulotMaksut || 0, 2) +
            lodashRound(o.tulotAvustusKp || 0, 2) +
            lodashRound(o.tulotAvustusPp || 0, 2) +
            lodashRound(o.tulotAvustusPpValtio || 0, 2) +
            lodashRound(o.tulotMuu || 0, 2), 2);
        return result;
    }
    function calcMenotYht() {
        var o = vm.app.td.num;
        var result = lodashRound(lodashRound(o.menotKpitoKesa || 0, 2) +
            lodashRound(o.menotKpitoTalvi || 0, 2) +
            lodashRound(o.menotPerusparannus || 0, 2) +
            lodashRound(o.menotHallinto || 0, 2) +
            lodashRound(o.menotMuu || 0, 2), 2);
        return result;
    }
    function calcTaseVastaavaa() {
        var o = vm.app.td.num;
        var result = lodashRound(lodashRound(o.kpitoRahavarat || 0, 2) +
            lodashRound(o.kpitoSaatavat || 0, 2) +
            lodashRound(o.kpitoMuuOmaisuus || 0, 2), 2);
        return result;
    }
    function calcTaseVastattavaa() {
        var o = vm.app.td.num;
        var result = lodashRound(lodashRound(o.kpitoOmaPaaoma || 0, 2) +
            lodashRound(o.kpitoTulos || 0, 2) +
            lodashRound(o.kpitoVelat || 0, 2), 2);
        return result;
    }
    vm.refreshCalculatedValues = function () {
        vm.tulotYht = calcTulotYht() || 0;
        vm.menotYht = calcMenotYht() || 0;
        vm.app.td.num.kpitoTulos = lodashRound(vm.tulotYht - vm.menotYht || 0, 2) || 0;
        vm.translationVars.kpitoTulos = vm.app.td.num.kpitoTulos;
        vm.app.td.num.kpitoTaseVastaavaa = calcTaseVastaavaa() || 0;
        vm.app.td.num.kpitoTaseVastattavaa = calcTaseVastattavaa() || 0;
    };
    vm.calcTiliSaldoErotus = function () {
        var o = vm.app.td.num;
        var result = lodashRound(lodashRound(o.kpitoLoppusaldo || 0, 2) -
            lodashRound(o.kpitoAlkusaldo || 0, 2), 2);
        vm.translationVars.tiliSaldoErotus = result;
        return result;
    };
    vm.ehdot = [
        'ehto_tiekunta', 'ehto_pankkitili', 'ehto_tiemaksut', 'ehto_mml', 'ehto_digiroad', 'ehto_ymparivuotinen', 'ehto_eikieltoja'
    ];
    vm.preprocessApp(vm.app);
}
