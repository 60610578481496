import angular from 'angular';
import './pp/pp-app';
import './pp/kp-app';
const tkrCustomModule = angular.module('tkr.custom');
tkrCustomModule.decorator('customizationConfig', CustomizationConfigDecorator);
CustomizationConfigDecorator.$inject = ['$delegate'];
function CustomizationConfigDecorator($delegate) {
    const cc = $delegate;
    return cc;
}
