import angular from 'angular';
import ngMessages from 'angular-messages';
import moment from 'moment';
import lodashRound from 'lodash/round';
import TkrAppConfig from '../../../common/TkrAppConfig';
import TkrSpaCommon from '../../../common/tkr-spa';
import { TkrPersonService } from '../../../common/tkr-commonservices';
import { SmartFloat, SmartInteger } from '../../../common/ma-smartfloat-1.0';
import TkrPpCommon from '../../../pp/common/0-pp-common-module';
import TkrPpResources from '../../../pp/prcportal-resource-modules';
import MaDatepicker from '../../../common/ma-datepicker-uib';
import { configureTranslateProvider } from '../../../pp/translation-helpers';
import ngTranslate from 'angular-translate';
import 'angular-translate-loader-static-files';
const subsidy_application_app = angular.module('ng-tkr-app-porvoopp', [
    TkrSpaCommon,
    ngMessages,
    TkrPersonService,
    SmartFloat,
    SmartInteger,
    TkrPpResources,
    TkrAppConfig,
    TkrPpCommon,
    MaDatepicker,
    ngTranslate,
])
    .config(config)
    .config(configureTranslateProvider);
export default subsidy_application_app.name;
config.$inject = ['$stateProvider', '$urlRouterProvider'];
function config($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/err');
    $stateProvider
        .state('err', {
        url: '/err',
        template: '<p class="h4 text-danger">Sisäinen virhe. Ota yhteyttä helpdesk@matriset.fi.</p>'
    })
        .state('root', {
        url: '',
        template: '',
        controller: 'AppRedirCtrl',
        controllerAs: 'vm'
    })
        .state('lomake', {
        url: '/:applicationId',
        template: require('./pp-app.tpl.html'),
        controller: 'SubsidyApplicationController',
        controllerAs: 'vm',
        resolve: {
            app: ['PortalSubsidyAppService', '$stateParams', function (PortalSubsidyAppService, $stateParams) {
                    return PortalSubsidyAppService.get({ appId: $stateParams.applicationId }).$promise;
                }],
            appPeriod: ['PortalAppPeriodService', 'app', function (PortalAppPeriodService, app) {
                    return PortalAppPeriodService.get({ periodId: app.applicationPeriodId }).$promise;
                }]
        }
    })
        .state('jatetty', {
        url: '/:applicationId/jatetty',
        template: require('./app-submitted.tpl.html'),
        controller: 'AppSubmittedController',
        controllerAs: 'vm'
    });
}
subsidy_application_app.controller('SubsidyApplicationController', SubsidyApplicationController);
SubsidyApplicationController.$inject = [
    'app',
    'appPeriod',
    'TkrPersonService',
    '$state',
    '$translate'
];
function SubsidyApplicationController(app, appPeriod, TkrPersonService, $state, $translate) {
    var vm = this;
    vm.app = app;
    vm.vuosi = appPeriod.td.wnum.vuosi;
    vm.edvuosi = vm.vuosi - 1;
    vm.loppuAika = moment(appPeriod.end).local().format('D.M.YYYY HH:mm');
    vm.translationVars = {
        loppuAika: vm.loppuAika
    };
    vm.personRoles = TkrPersonService.personRoles;
    vm.attachmentDefs = [
        { type: 'kartta', name: 'karttaliite', required: true },
        { type: 'poytakirja', name: 'kokouksen_poytakirja', required: true },
        { type: 'selvitys', name: 'selvitys_tehtavasta_tyosta', required: false }
    ];
    vm.preprocessApp = function (data) {
        if (!data.chairman) {
            data.chairman = {};
        }
        if (!data.contactPerson) {
            data.contactPerson = {};
        }
        if (!data.addressNames) {
            data.addressNames = [];
        }
        if (data.addressNames.length == 0) {
            data.addressNames.push({ road: '', town: null });
        }
        if (!data.td) {
            data.td = {};
        }
        if (!data.td.num) {
            data.td.num = {};
        }
        if (!data.td.wnum) {
            data.td.wnum = {};
        }
        if (!data.td.bools) {
            data.td.bools = {};
        }
        if (!data.td.text) {
            data.td.text = {};
        }
    };
    vm.preprocessApp(vm.app);
    vm.postprocessApp = function (data) {
    };
    vm.save = function () {
        vm.postprocessApp(vm.app);
        vm.app.applicationState = 'SUBMITTED';
        vm.app.$save({
            lang: $translate.use()
        }).then(function onSuccess(data) {
            if (data.id && data.id === vm.app.id) {
                $state.go('jatetty', { applicationId: data.id });
            }
            else {
                window.alert('VIRHE hakemusta ei tallennettu --- FEL bidragsansökan sparades inte');
            }
        });
    };
    vm.toimenpiteet = {
        maanleikkaus: { name: 'pp_maanleikkaus' },
        kallionleikkaus: { name: 'pp_kallionleikkaus' },
        soraa: { name: 'pp_soraa' },
        murskesoraa: { name: 'pp_murskesoraa' },
        ojitus: { name: 'pp_ojitus' },
        rummut: { name: 'pp_rummut' },
        muu: { name: 'pp_muu' }
    };
    vm.rowSums = {};
    vm.calcRowSum = function (id) {
        var td = vm.app.td;
        vm.rowSums[id] = lodashRound(td.num['maara_' + id] * td.num['hinta_' + id] || 0, 2);
        return vm.rowSums[id];
    };
    vm.calcTotal = function () {
        var total = 0;
        for (var k in vm.rowSums) {
            total += vm.rowSums[k];
        }
        return lodashRound(total, 2);
    };
    vm.ehdot = [
        'ehto_pp_paatos',
        'ehto_pp_valtionavustus',
        'ehto_pp_toteutus'
    ];
}
